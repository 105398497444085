@import '/src/styles/utilities.scss';

.showFeatureHeader {
  margin-bottom: $space-36;

  @include media-breakpoint-between(md, lg) {
    margin-bottom: $space-40;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-52;
  }
}
